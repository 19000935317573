import * as React from 'react'
import useSubmitContactForm from 'src/apollo/mutation/useSubmitContactForm'
import Loader from '../Login/Loader/Loader'
import { form, confirm, confirmHeader} from './ContactForm.module.scss'
import FormFields from './FormFields'

const ContactForm = () => {
  const [success, setSuccess] = React.useState(false)
  const [valid, setValid] = React.useState(false)

  const formRef = React.createRef()
  const [submitContactForm, { loading, error }] = useSubmitContactForm({
    onCompleted: () => setSuccess(true),
    onError: () => null,
  })

  const handleSubmit = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const params = {}
    formData.forEach(function(value, key) {
      params[key] = value
    })

    submitContactForm({ variables: { params } })
  }

  const validate = () => {
    const valid = formRef.current.checkValidity()
    setValid(valid)
  }

  if (loading) return <Loader transparent/>

  return (
    <div className={`${form} small-12 medium-12 large-10 xlarge-8`}>
      {!success && !error.message && (
        <FormFields
          ref={formRef}
          valid={valid}
          handleSubmit={handleSubmit}
          validate={validate}/>
      )}
      {success && (
        <div className={confirm}>
          <h2 className={confirmHeader}>Message Received</h2>
        </div>
      )}
      {error.message && (
        <div className={confirm}>
          <h2 className={confirmHeader}>There was an error sending the message</h2>
        </div>
      )}

    </div>
  )

}

export default ContactForm
