import * as React from 'react'
import { field, fieldInput, fieldTextArea, fieldWithValue, fieldLabel } from './ContactForm.module.scss'

class ContactFormField extends React.Component {
  state = {
    hasValue: false,
    focused: false,
  }

  onChange(evt) {
    this.setState({ hasValue: evt.target.value !== '' })
    this.props.validate()
  }

  render() {
    let { id, label, type, required, pattern } = this.props
    return (
      <div className={field}>
        {type === 'longText' ? (
          <textarea
            className={fieldTextArea}
            name={id}
            rows="1"
            cols="40"
            onChange={this.onChange.bind(this)}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
          />
        ) : (
          <input
            id={id}
            className={fieldInput}
            name={id}
            type={type}
            required={required}
            pattern={pattern}
            onChange={this.onChange.bind(this)}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
          />
        )}
        <label htmlFor={id} className={this.state.hasValue || this.state.focused ? fieldWithValue : fieldLabel}>
          {label}
        </label>
      </div>
    )
  }
}

export default ContactFormField
