export const contactPageData = {
  paragraph: 'Emso is based in London, Greenwich, and Miami. Our business, investors, and research are global.',
  disclaimer:
    "Please only use this form for business inquiries. For any inquiries about job opportunities please contact <a href='mailto:recruitment@emso.com'>recruitment@emso.com.</a>",
}

export const contactFormFields = [
  { id: 'name', label: 'Name', type: 'text', required: true },
  { id: 'company', label: 'Company', type: 'text' },
  { id: 'country', label: 'Country', type: 'text' },
  {
    id: 'phone',
    label: 'Phone Number',
    type: 'tel',
    required: true,
    pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$',
  },
  { id: 'email', label: 'Email', type: 'email', required: true },
  { id: 'message', label: 'Message', type: 'longText' },
]

export const contactLocations = {
  london: {
    title: 'London',
    mediaInquiryEmail: 'media@emso.com',
    locations: [
      {
        locationFirstLine: '110 Park Street',
        locationSecondLine: 'London, W1K 6NX',
        locationLink:
          'https://www.google.com/maps/place/110+Park+St,+London+W1K+6NX/@51.5128862,-0.1579104,17z/data=!3m1!4b1!4m6!3m5!1s0x48760532e33849d3:0xc2c2df636629ed2d!8m2!3d51.5128829!4d-0.1553355!16s%2Fg%2F11cs996n0w?entry=ttu',
      },
    ],
    phoneNumber: '+44 20 7150 3700',
  },
  america: {
    title: 'Emso Asset Management US LLC',
    mediaInquiryEmail: 'media@emso.com',
    phoneNumber: '+1 212 307 8900',
    locations: [
      {
        locationFirstLine: 'One Fawcett Place',
        locationSecondLine: 'Suite 150',
        locationThirdLine: 'Greenwich, CT 06830',
        locationLink: 'https://goo.gl/maps/FP4fDw7aac3Ye8w66',
      },
      {
        locationFirstLine: '600 Brickell Avenue',
        locationSecondLine: 'Suite 1705',
        locationThirdLine: 'Miami, FL 33131',
        locationLink: 'https://goo.gl/maps/sTRrJkwzWQHTs3KfA',
      },
    ],
  },
}
