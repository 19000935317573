import React from 'react'
import { contactFormFields } from '../../data/contact'
import { formButton, disabledFormButton, disclaimer, fields } from './ContactForm.module.scss'
import FormField from './FormField'

const FormFields = React.forwardRef(({ valid, handleSubmit, validate }, ref) => {
  return (
    <form id="contactForm" ref={ref} onSubmit={handleSubmit}>
      <div className={fields}>
        {contactFormFields.map((field, index) => (
          <FormField key={index} {...field} validate={validate} />
        ))}
      </div>
      <input className={valid ? formButton : disabledFormButton} type="submit" value="Submit" />
      <p className={disclaimer}>
        Please only use this form for business inquiries. For any inquiries about job opportunities please contact{' '}
        <a href="mailto:recruitment@emso.com">recruitment@emso.com.</a>
      </p>
    </form>
  )
})

export default FormFields
