import { Waypoint } from 'react-waypoint'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import * as layoutStyles from '../layouts/Layout.module.scss'
import ContactForm from '../components/ContactForm/ContactForm'
import { ContactInformation } from '../components/ContactInformation/ContactInformation'
import { contact, contactForm, contactInformation, contactPage } from './contact.module.scss'

export default class Contact extends React.Component {
  state = {
    contactHidden: true,
  }

  contactVisible() {
    this.setState({ contactHidden: false })
  }

  render() {
    return (
      <Layout getInTouchVisible={false} blueFooter={true} className={contactPage}>
        <Helmet>
          <title>Contact - Emso</title>
          <meta name="description" content="Emso is based in London, Greenwich and Miami. Our business, investors, and research are global." />
        </Helmet>
        <Waypoint onEnter={this.contactVisible.bind(this)}>
          <div data-hidden={this.state.contactHidden} className={layoutStyles.row}>
            <section className={contact}>
              <div className={contactInformation}>
                <ContactInformation />
              </div>
              <div className={contactForm}>
                <ContactForm />
              </div>
            </section>
          </div>
        </Waypoint>
      </Layout>
    )
  }
}
