import { useMutation as useMutationApollo } from '@apollo/client'
import { notification } from 'antd'
import { getErrorMessage, parseErrorMessage } from '../helpers/error'

const useMutation = (mutation, optionsInput = {}) => {
  const { onError, showErrorNotification = true, ...options } = optionsInput
  const handleError = error => {
    if (onError) {
      onError(error)
    }
    if (showErrorNotification) {
      notification.error({ message: getErrorMessage(error) })
    }
  }
  const [mutate, state] = useMutationApollo(mutation, { onError: handleError, ...options })
  return [mutate, { ...state, error: { ...state.error, message: parseErrorMessage(state?.error?.message) } }]
}

export default useMutation
