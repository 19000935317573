// extracted by mini-css-extract-plugin
export var address = "emso-ContactInformation-module__address emso-ContactInformation-module__media";
export var america = "emso-ContactInformation-module__america emso-ContactInformation-module__location";
export var intro = "emso-ContactInformation-module__intro";
export var introHeader = "emso-ContactInformation-module__introHeader";
export var introParagraph = "emso-ContactInformation-module__introParagraph";
export var location = "emso-ContactInformation-module__location";
export var locations = "emso-ContactInformation-module__locations";
export var locationsHeader = "emso-ContactInformation-module__locationsHeader";
export var london = "emso-ContactInformation-module__london emso-ContactInformation-module__location";
export var media = "emso-ContactInformation-module__media";
export var mediaHeader = "emso-ContactInformation-module__mediaHeader";
export var mediaParagraph = "emso-ContactInformation-module__mediaParagraph";
export var mediaParagraphLink = "emso-ContactInformation-module__mediaParagraphLink";
export var phone = "emso-ContactInformation-module__phone emso-ContactInformation-module__media";