import * as React from 'react'
import { contactPageData, contactLocations } from '../../data/contact'
import {
  media as mediaStyle,
  mediaHeader,
  mediaParagraph,
  mediaParagraphLink,
  locationsHeader,
  address,
  phone,
  intro,
    america,
  london,
  locations,
  introHeader,
  introParagraph,
} from './ContactInformation.module.scss'
import {Fragment} from "react";

const title = 'Contact Us'
const media = 'Media inquiries'

const MediaInquiries = ({ mediaInquiryEmail }) => {
  return (
    <div className={mediaStyle}>
      <span className={mediaHeader}>{media}</span>
      <br />
      <p className={mediaParagraph}>
        <a className={mediaParagraphLink} href={`mailto:${mediaInquiryEmail}`}>
          {mediaInquiryEmail}
        </a>
      </p>
    </div>
  )
}

const ContactLocation = ({className, phoneNumber, title, mediaInquiryEmail, locations = []}) => {
  return (
      <div className={`${className}`}>
          <h4 className={locationsHeader}>{title}</h4>
          <a href={`tel:${phoneNumber}`} className={phone}>
              {phoneNumber}
          </a>
          {locations.map((location)=> {
              const {
                  locationLink,
                  locationFirstLine,
                  locationSecondLine,
                  locationThirdLine
              } = location;
              return (
                  <Fragment key={locationThirdLine}>
                      <a href={locationLink} target="_blank" rel="noopener noreferrer" className={address}>
                          <span className={mediaHeader}>{locationFirstLine}</span>
                          <p>{locationSecondLine}</p>
                          {locationThirdLine && <p>{locationThirdLine}</p>}
                      </a>
                  </Fragment>
              )
             })
          }
          {title === 'London' ? <MediaInquiries mediaInquiryEmail={mediaInquiryEmail} /> : null}
      </div>
  )
}

export const ContactInformation = () => {
  return (
    <div className={intro}>
      <h1 className={introHeader}>{title}</h1>
      <p className={introParagraph}>{contactPageData.paragraph}</p>
      <div className={locations}>
        <ContactLocation className={london} {...contactLocations.london} />
        <ContactLocation className={america} {...contactLocations.america} />
      </div>
    </div>
  )
}
