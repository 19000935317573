import gql from 'graphql-tag'
import useMutation from './useMutation'

export const submitContactFormMutation = gql`
    mutation SubmitContactForm($params: SubmitContactFormInput!) {
        submitContactForm(params:$params)
    }
`

const useSubmitContactForm = ({ params, ...options } = {}) => {
  return useMutation(submitContactFormMutation, {
    variables: { params }, ...options,
  })
}

export default useSubmitContactForm
